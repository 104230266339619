@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'handwritting';
  src: url('../fonts/handwritting.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/handwritting.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/handwritting.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/handwritting.svg#handwritting') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #243021;
  margin: 0;
  padding: 0;
}
.Dropdown-control {

}

.Dropdown-option {
  background-color: #252525;
  color: #FD5839;
}

.Dropdown-option:hover {
  background-color: #B1B900;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #B1B900;
}

#bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

#app {
  #debugger {
    position: fixed;
    top: 0;
    border: 1px solid white;
    color: white;
    display: none;
  }

  #main-component {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 230px);

    .wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: 1000ms;
      animation-delay: 600ms;
      animation-fill-mode: forwards;
    }
  }

  #tournament-component {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes button-grow {
  from { transform: scale(1); }
  to { transform: scale(1.1); }
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media (max-width: 975px) {
  #app #main-component { min-height: 130vh; }
}
