.item {
  &.off { display: none; }

  img {
    width: 340px;
    clip-path: ellipse(40% 46% at 50% 50%);
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 400ms;
    animation-delay: 400ms;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 975px) {
  .item {
    img { width: 50vw; }
  }
}
