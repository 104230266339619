#connect-container {
  margin-top: 75px;

  #connect-button {
    all: unset;
    width: 40vw;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    margin-top: 75px;
    animation: button-grow infinite 3s 2s linear alternate;

    img { width: 100%; }
  }
}
