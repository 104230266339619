#feed-container {
  #feed-button {
    all: unset;
    width: 240px;
    height: auto;
    margin-top: 14px;
    animation: button-grow infinite 3s 2s linear alternate;

    img { width: 100%; }
  }

  #feed-disclaimer {
    width: 340px;
    margin-top: 30px;
    opacity: 0.65;
  }
}

@media (max-width: 975px) {
  #feed-container {
    #feed-button {
      width: 200px;
    }
  }
}
