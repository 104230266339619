#champion {
  position: relative;
  #champ-overlay {
    position: absolute;
    top: -50px;
    width: 180%;
    height: 97%;
    background-image: url('../media/sparkle.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }
  #behold { width: 500px; margin-bottom: 20px; }
  #champ-img { width: 400px; }
  #champ-name { font-family: courier; color: white; }
  #see-you { width: 400px; margin-top: 50px; }
}

.champion-fade {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 6000ms;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
}
