#feed-container {
  #collections-container {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
    gap: 40px;

    .frame {
      width: 281px;
      height: 332px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      background-image: url('/media/frame.svg');
      background-size: contain;
    }

    .switcher {
      &.on {
        display: flex;
        align-items: center;
        .prevBtn, .nextBtn {
          width: 30px;
          height: 42px;
          background-size: contain;
          opacity: 0.2;
          pointer-events: none;
          display: block;

          &.on {
            opacity: 1;
            pointer-events: auto;
          }
        }
        .prevBtn { background-image: url('/media/prev-arrow.svg'); }
        .nextBtn { background-image: url('/media/next-arrow.svg'); }
      }
      .prevBtn, .nextBtn { display: none; }
    }

    #howlerzContainer, #preyContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .avatarContainer {
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        padding: 40px;
        background-image: url('../media/avatar-bg.jpg');
      }
    }

    .label {
      width: 135px;
      margin-top: 10px;
    }
  }
}

@media (max-width: 975px) {
  #feed-container {
    #collections-container {
      flex-direction: column;

      .frame { width: 43vw; height: 51vw; }
      .label { width: 110px; }
    }
  }
}
