header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 30px 0 30px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1000ms;
  animation-delay: 300ms;
  animation-fill-mode: forwards;

  #info {
    width: 225px;
  }

  #logo {
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;

    &.null {
      background-image: url('../media/logo.png');
      width: 200px;
      height: 200px;

      &:hover {
        animation: logo-spin infinite 20s linear;
      }
    }

    &.stake {
      background-image: url('../media/select-stake.png');
      width: 491px;
      height: 200px;
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: 1000ms;
      animation-delay: 300ms;
      animation-fill-mode: forwards;
    }
  }

  #social-links {
    width: 255px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    img { width: 115px; }
    #twitter-link, #discord-link {
      position: relative;

      &::after {
        content: "";
        background-image: url('/media/underline.svg');
        background-size: cover;
        height: 6px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: -2px;
        z-index: 1;
        opacity: 0;
        transition: opacity 300ms;
      }

      &:hover::after {
        opacity: 1;
      }
    }

    #twitter-link { margin-right: 25px; }
  }

}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  header {
    margin: 0 30px 0 30px;
    #info, #social-links { display: none; }
    #logo {
      margin: auto;

      #logo-img {
        width: 150px;
      }
    }
  }
}
