#stake-container {
  #champions-container {
    width: 80vw;
    max-width: 1050px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    justify-content: space-around;
    div {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        right: 66px;
        top: 52px;
        background-image: url('../media/unchecked.svg');
        background-size: cover;
        z-index: 1;
      }
      img {
        width: 340px;
        clip-path: ellipse(43% 50% at 50% 50%);
        opacity: 0.75;
      }
      &.staked {
        &::before { background-image: url('../media/checked.svg'); }
        img { opacity: 1; }
      }
    }
  }
  button {
    all: unset;
    width: 70vw;
    max-width: 440px;
    height: auto;
    margin: 40px 0 100px 0;
    cursor: not-allowed;

    img { width: 100%; opacity: 0.5; }

    &.enabled {
      cursor: pointer;
      img { opacity: 1; }
    }
  }
}

@media (max-width: 975px) {
  #stake-container {
    button {
      width: 300px;
    }
  }
}
