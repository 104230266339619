#burn-or-stake {
  button {
    all: unset;
    width: 60vw;
    max-width: 500px;
    height: auto;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      animation: button-grow infinite 3s 500ms linear alternate;
    }

    img { width: 100%; }
  }
  #burn { margin-top: 40px; }
  #or { width: 100px; margin-left: 200px; }
  #stake { margin-top: 10px; margin-left: 65px; }
}
